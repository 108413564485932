import classNames from 'classnames';
import styles from './index.module.scss';

type TextComponents = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label';
type Variants = 'heading1' | 'body1' | 'subheading1';

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
    component?: TextComponents;
    variant?: Variants;
    children?: React.ReactNode;
}

export const Typography = ({
    component = 'p',
    variant = 'body1',
    children,
    ...props
}: TypographyProps) => {
    const Component = component;
    const className = classNames({
        [styles.heading1]: variant === 'heading1',
        [styles.body1]: variant === 'body1',
        [styles.subheading1]: variant === 'subheading1',
    });

    return (
        <Component className={className} {...props}>
            {children}
        </Component>
    );
};
