import React from 'react';
import LayoutPolicy from '../../../Layout/Policy/layout-policy';
import Copydeck from '../../../i18n/Copydeck';

const CustomTermsConditionsPage = () => {
    return (
        <LayoutPolicy>
            <LayoutPolicy.Title title={Copydeck.customerTermsConditionsCtcTitle} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText1} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText2} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText3} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText4} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText5} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText6} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText7} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText8} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText9} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText10} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText11} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText12} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText13} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText14} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText15} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText16} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText17} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText18} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText19} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText20} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText21} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText22} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText23} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText24} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText25} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText26} />

            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText27} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText28} />

            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText29} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText30} />

            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText31} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText32} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText33} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText34} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText35} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText36} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText37} />

            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText38} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText39} />

            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText40} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText41} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText42} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText43} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText44} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText45} />

            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText46} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText47} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText48} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText49} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText50} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText51} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText52} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText53} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText54} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText55} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText56} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText57} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText58} />

            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText59} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText60} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText61} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText62} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText63} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={Copydeck.customerTermsConditionsCtcText64} />
            <LayoutPolicy.Jump />
        </LayoutPolicy>
    );
};

export default CustomTermsConditionsPage;
