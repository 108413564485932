import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Container } from 'aurelia-dependency-injection';
import 'reflect-metadata';
import './index.scss';

// Setup i18n
import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Setup DI
const rootContainer = new Container();
rootContainer.makeGlobal();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
);
