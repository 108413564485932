import { Cardholder, Headset, MonitorArrowUp, Devices } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useMeasure from 'react-use-measure';

import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import CONSTANTS from '../../Constants';
import { useStores } from '../../Hooks/use-stores';
import headerImage from '../../Assets/Img/PartnerPortal/internal-testing-bg.jpg';
import { Tour } from '../../Features/partner-portal/Tour';

import 'shepherd.js/dist/css/shepherd.css';
import './shepherd.scss';
import styles from './index.module.scss';

export const PartnerPortal = observer(() => {
    const { userStore } = useStores();
    const [startTour, setStartTour] = useState(false);
    const [ref, bounds] = useMeasure();

    useEffect(() => {
        if (!userStore.userInfo.onboarded && bounds.width > 850) {
            setTimeout(() => {
                setStartTour(true);
            }, 500);
        }
    }, [userStore.userInfo.onboarded, bounds]);

    return (
        <main className={styles.main} ref={ref}>
            {startTour && <Tour />}
            <div className={styles.container}>
                <div className={styles.layout}>
                    <div>
                        <Typography variant="heading1" component="h1" style={{ color: '#2b2b2b' }}>
                            Hello, {userStore.userInfo.first_name} {userStore.userInfo.last_name}!
                        </Typography>
                    </div>
                    <div className={styles.top}>
                        <article
                            id="internal-testing"
                            className={styles.featureCard}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                        >
                            <img
                                src={headerImage}
                                className={styles.backgroundImage}
                                alt="phone with colorful background"
                            />
                            <div className={styles.overlay} />
                            <div className={styles.featureInfo}>
                                <div className={styles.featureDescription}>
                                    <Devices fill="#ffffff" size={70} weight="duotone" />
                                    <Typography style={{ color: '#ffffff' }}>
                                        Internal Testing
                                    </Typography>
                                    <Typography style={{ color: '#ffffff' }}>
                                        Test personalized experiences with the Protea app to explore
                                        its features and ensure seamless integration with your
                                        business needs.
                                    </Typography>
                                </div>
                            </div>
                            <Link pathConfig={{ where: CONSTANTS.SCREEN.INTERNAL_TESTING }} />
                        </article>
                    </div>
                    <div className={styles.bottom}>
                        <Link
                            className={styles.link}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                            id="assets"
                            pathConfig={{ where: CONSTANTS.SCREEN.MANAGE_ASSESTS }}
                        >
                            <MonitorArrowUp fill="#2b2b2b" size={32} /> Manage Assets
                        </Link>
                        <Link
                            className={styles.link}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                            id="billing"
                            pathConfig={{ where: CONSTANTS.SCREEN.BILLING }}
                        >
                            <Cardholder fill="#2b2b2b" size={32} /> Billing & Payment
                        </Link>
                        <Link
                            className={styles.link}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                            id="support"
                            pathConfig={{ where: CONSTANTS.SCREEN.SUPPORT }}
                        >
                            <Headset fill="#2b2b2b" size={32} /> Contact Support
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    );
});
