import { FormEvent, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';

import { Button2 } from '../../Components/Button2';
import { useStores } from '../../Hooks/use-stores';
import { entitleKeys } from '../partner-portal/queries';

export const EarlyAccessCheckoutForm = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const { userStore } = useStores();

    const stripe = useStripe();
    const elements = useElements();
    const queryClient = useQueryClient();

    const userId = userStore.userInfo.id;

    const handleError = (error: StripeError | undefined) => {
        setLoading(false);
        setErrorMessage(error?.message ?? 'An unknown error occurred');
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        // Create the subscription
        const res = await fetch('http://localhost:8000/subscriptions/usage-based', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
            }),
        });
        const { clientSecret } = await res.json();

        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
                return_url: 'http://localhost:3000/payments-success',
            },
        });

        setLoading(false);

        if (error) {
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError(error);
        } else {
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            queryClient.invalidateQueries({ queryKey: entitleKeys.byId(userStore.userInfo.id) });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button2>{loading ? 'Payment Pending' : 'Get Early Access'}</Button2>
        </form>
    );
};
