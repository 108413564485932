import { Typography } from '../../../../Components/Typography';
import col1Image1 from '../../../../Assets/Img/Home/Column1_Image1.jpg';
import col1Image2 from '../../../../Assets/Img/Home/Column1_Image2.jpg';
import col1Image3 from '../../../../Assets/Img/Home/Column1_Image3.jpg';
import col2Image1 from '../../../../Assets/Img/Home/Column2_Image1.jpg';
import col2Image2 from '../../../../Assets/Img/Home/Column2_Image2.jpg';
import col2Image3 from '../../../../Assets/Img/Home/Column2_Image3.jpg';
import col2Image4 from '../../../../Assets/Img/Home/Column2_Image4.jpg';

import styles from './index.module.scss';
import Copydeck from '../../../../i18n/Copydeck';

export const Partnerships = () => {
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.left}>
                    <Typography variant="heading1" component="h2">
                        {Copydeck.homePagePartnerSectionPartnerTitle}
                    </Typography>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePagePartnerSectionPartnerSubtitle,
                        }}
                    />
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePagePartnerSectionPartnerSubtitle2,
                        }}
                    />
                </div>
                <div className={styles.right}>
                    <div className={styles.col}>
                        <div className={styles.colMarquee}>
                            <img
                                src={col1Image1}
                                alt="Carnegie Mellon University campus with overlayed text"
                            />
                            <img
                                src={col1Image2}
                                alt="John Imah speaking with Carnegia Melon employee"
                            />
                            <img src={col1Image3} alt="Group of people in robotics lab" />
                        </div>
                        <div aria-hidden="true" className={styles.colMarquee}>
                            <img
                                src={col1Image1}
                                alt="Carnegie Mellon University campus with overlayed text"
                            />
                            <img
                                src={col1Image2}
                                alt="John Imah speaking with Carnegia Melon employee"
                            />
                            <img src={col1Image3} alt="Group of people in robotics lab" />
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.colMarquee}>
                            <img src={col2Image1} alt="Man lookng at work at MIT" />
                            <img
                                src={col2Image2}
                                alt="John Imah with MIT Employee at College of Computing"
                            />
                            <img src={col2Image3} alt="MIT logo overlayed campus" />
                            <img src={col2Image4} alt="John Imah walking down MIT halls" />
                        </div>
                        <div className={styles.colMarquee} aria-hidden="true">
                            <img src={col2Image1} alt="Man lookng at work at MIT" />
                            <img
                                src={col2Image2}
                                alt="John Imah with MIT Employee at College of Computing"
                            />
                            <img src={col2Image3} alt="MIT logo overlayed campus" />
                            <img src={col2Image4} alt="John Imah walking down MIT halls" />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.rowMarquee}>
                            <div>
                                <img
                                    src={col1Image1}
                                    alt="Carnegie Mellon University campus with overlayed text"
                                />
                            </div>
                            <div>
                                <img
                                    src={col1Image2}
                                    alt="John Imah speaking with Carnegia Melon employee"
                                />
                            </div>
                            <img src={col1Image3} alt="Group of people in robotics lab" />
                        </div>
                        <div className={styles.rowMarquee} aria-hidden="true">
                            <img
                                src={col1Image1}
                                alt="Carnegie Mellon University campus with overlayed text"
                            />
                            <img
                                src={col1Image2}
                                alt="John Imah speaking with Carnegia Melon employee"
                            />
                            <img src={col1Image3} alt="Group of people in robotics lab" />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.rowMarquee}>
                            <img src={col2Image1} alt="Man lookng at work at MIT" />
                            <img
                                src={col2Image2}
                                alt="John Imah with MIT Employee at College of Computing"
                            />
                            <img src={col2Image3} alt="MIT logo overlayed campus" />
                            <img src={col2Image4} alt="John Imah walking down MIT halls" />
                        </div>
                        <div className={styles.rowMarquee} aria-hidden="true">
                            <img src={col2Image1} alt="Man lookng at work at MIT" />
                            <img
                                src={col2Image2}
                                alt="John Imah with MIT Employee at College of Computing"
                            />
                            <img src={col2Image3} alt="MIT logo overlayed campus" />
                            <img src={col2Image4} alt="John Imah walking down MIT halls" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
