import { CheckmarkCircle } from '../../../Icons/CheckmarkCircle';
import { GlobeHemisphereWestIcon } from '../../../Icons/globe-gemisphere-west-icon';
import { IslandIcon } from '../../../Icons/island-icon';
import { VideoConferenceIcon } from '../../../Icons/VideoConferenceIcon';
import sharedStyles from '../index.module.scss';
import { FadeUp } from '../../../Components/FadeUp';
import styles from './styles/about.module.scss';

export const About = () => {
    return (
        <section style={{ backgroundColor: ' #F9F9F9' }} className={styles.sectionWrapper}>
            <div className={styles.sectionContainer}>
                <div className={styles.header}>
                    <FadeUp delay={0.1}>
                        <h2 className={sharedStyles.heading2}>About SpreeAI</h2>
                    </FadeUp>
                    <FadeUp delay={0.2}>
                        <p className={sharedStyles.body1}>
                            Our global, fully-remote workplace fosters growth opportunities and
                            offers a wealth of resources to support your success and well-being.
                        </p>
                    </FadeUp>
                </div>
                <div className={styles.benefits}>
                    <FadeUp delay={0.1}>
                        <h2 className={styles.benefitsHeader}>10+ Countries</h2>
                        <div className={styles.benefitsDetails}>
                            <GlobeHemisphereWestIcon fill="#2b2b2b" />
                            <p>A Global Team Presence</p>
                        </div>
                    </FadeUp>
                    <FadeUp delay={0.2}>
                        <h2 className={styles.benefitsHeader}>100% Remote</h2>
                        <div className={styles.benefitsDetails}>
                            <VideoConferenceIcon />
                            <p>A fully-remote and flexible workplace</p>
                        </div>
                    </FadeUp>
                    <FadeUp delay={0.3}>
                        <h2 className={styles.benefitsHeader}>Unlimited PTO</h2>
                        <div className={styles.benefitsDetails}>
                            <IslandIcon fill="#2b2b2b" />
                            <p>
                                Recharge and stay balanced with our unlimited paid time off policy
                            </p>
                        </div>
                    </FadeUp>
                </div>
                <div className={styles.plus}>
                    <FadeUp delay={0.1}>
                        <h3>Our Perks & Benefits</h3>
                    </FadeUp>
                    <div>
                        <FadeUp delay={0.1}>
                            <ul className={styles.plusBenefitsList}>
                                <li className={sharedStyles.body1}>
                                    <CheckmarkCircle fill="#2DB67D" /> Health Insurance
                                </li>
                                <li className={sharedStyles.body1}>
                                    <CheckmarkCircle fill="#2DB67D" /> Work Alongside Leaders &
                                    Influencers in the Fashion Industry
                                </li>
                            </ul>
                        </FadeUp>
                        <FadeUp delay={0.2}>
                            <ul className={styles.plusBenefitsList}>
                                <li className={sharedStyles.body1}>
                                    <CheckmarkCircle fill="#2DB67D" /> Retirement Savings Plan
                                </li>
                                <li className={sharedStyles.body1}>
                                    <CheckmarkCircle fill="#2DB67D" /> Mental Wellness (Access to
                                    apps)
                                </li>
                            </ul>
                        </FadeUp>
                        <FadeUp delay={0.3}>
                            <ul className={styles.plusBenefitsList}>
                                <li className={sharedStyles.body1}>
                                    <CheckmarkCircle fill="#2DB67D" /> Employment Based Sponsorship
                                </li>
                                <li className={sharedStyles.body1}>
                                    <CheckmarkCircle fill="#2DB67D" /> Holidays + Winter Break
                                </li>
                            </ul>
                        </FadeUp>
                    </div>
                </div>
            </div>
        </section>
    );
};
