import { useEffect, useState } from 'react';
import { ArrowRight, Check } from '@phosphor-icons/react';
import * as Checkbox from '@radix-ui/react-checkbox';
import classNames from 'classnames';

import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';

import { BillingPortalButton } from '../../Features/payments/BillingPortalButton';
import { useGetBillingPortalUrl } from '../../Features/payments/queries';
import { useStores } from '../../Hooks/use-stores';
import styles from './index.module.scss';
import { BackButton } from '../../Features/partner-portal/BackButton';
import { useCustomPath } from '../../Hooks/use-custom-path';
import CONSTANTS from '../../Constants';
import { useNavigate } from 'react-router-dom';

const features = [
    'Continuous Improvements & Optimization',
    'Analytics & User Feedback',
    'UX/UI White Labeling',
    'Account Manager',
    'Technical Support',
];

export const Pricing = () => {
    const [checked, setChecked] = useState<boolean | 'indeterminate'>('indeterminate');

    const { userStore } = useStores();
    const { data, refetch, isLoading } = useGetBillingPortalUrl({ userId: userStore.userInfo.id });
    const phase1Path = useCustomPath({ where: CONSTANTS.SCREEN.PHASE_ONE });
    const navigate = useNavigate();

    if (data?.url) {
        window.location.href = data.url;
    }

    useEffect(() => {
        if (userStore.userInfo.agreed_to_terms) {
            navigate(phase1Path);
        }
    });

    const handleVisitBillingPortal = () => {
        refetch();
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (checked) {
            userStore.setUserInfo({ agreed_to_terms: new Date().toISOString() });
            navigate(phase1Path);
        }
    };

    return (
        <main className={styles.main}>
            <div className={classNames([styles.container, styles.left])}>
                <div className={styles.wrapper}>
                    <div className={styles.description}>
                        <BackButton />
                        <h2>Confirm to continue with Internal Testing</h2>
                        <Typography>
                            As you start exploring and personalizing your testing experience, we
                            want to ensure you're aware of the cost associated with generating
                            try-ons on our app. Take a moment to review the terms below to fully
                            understand the details before proceeding.
                        </Typography>
                    </div>
                    {/* <div>
                        <Typography style={{ paddingBottom: '16px' }}>
                            If you would like to add a payment method at this time you can do so.
                            However, this isn't necessary to move forward and can be updated at any
                            time from your billing page.
                        </Typography>
                        <button className={styles.button} onClick={handleVisitBillingPortal}>
                            Update billing method <ArrowRight size={24} />
                        </button>
                    </div> */}
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <div>
                            <Checkbox.Root
                                className={styles.Root}
                                id="c1"
                                checked={checked}
                                onCheckedChange={setChecked}
                            >
                                <Checkbox.Indicator className={styles.Indicator}>
                                    {checked !== 'indeterminate' ? (
                                        <Check fill="#2b2b2b" size={20} weight="bold" />
                                    ) : null}
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                            <Typography component="label">
                                By checking this box, I acknowledge and agree that my payment method
                                will be automatically charged and billed every end of the month, and
                                I authorize these recurring payments.
                            </Typography>
                        </div>

                        <Button2
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={!checked || checked === 'indeterminate'}
                        >
                            Accept & Continue
                        </Button2>
                    </form>
                </div>
            </div>
            <div className={classNames([styles.container, styles.right])}>
                <div className={styles.rightWrapper}>
                    <div>
                        <div className={styles.price}>$0.50</div>
                        <Typography component="h4">Per Try-On Generated</Typography>
                    </div>
                    <div>
                        <Typography>With All Costs Including:</Typography>
                        <ul className={styles.featureList}>
                            {features.map((feature, index) => (
                                <li key={index}>
                                    <Check fill="#2FB481" size={32} weight="bold" />
                                    <Typography>{feature}</Typography>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
};
