import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IUseCustomPath, useCustomPath } from '../../Hooks/use-custom-path';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children?: React.ReactNode;
    className?: string;
    pathConfig: IUseCustomPath;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
    ({ pathConfig, children, className, ...props }, ref) => {
        const customPath = useCustomPath(pathConfig);

        return (
            <RouterLink ref={ref} to={customPath} className={className} {...props}>
                {children}
            </RouterLink>
        );
    }
);
