import { toast } from 'react-toastify';
import CONSTANTS from '../Constants';
import { IS_DEV } from '../Utils/object';

class Service {
    public async wrapperTryCatch<T>(func: () => Promise<T>) {
        try {
            const result = await func();
            return result;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    public async get(url: string, isExternal?: boolean) {
        let header: any = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
        if (accessToken && isExternal !== true) {
            header.headers['X-Cognito-Auth'] = accessToken;
        }

        return await this.wrapperTryCatch(async () => {
            if (isExternal) {
                return await fetch(url, header);
            } else {
                return await fetch(this.getApiBase() + url, header);
            }
        });
    }

    public async delete(url: string) {
        let header: any = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
        if (accessToken) {
            header.headers['X-Cognito-Auth'] = accessToken;
        }

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);

            return response;
        });
    }

    public async post(url: string, body: any, isAnonymous?: boolean) {
        let header: any = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
        if (accessToken && !isAnonymous) {
            header.headers['X-Cognito-Auth'] = accessToken;
        }

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);

            return response;
        });
    }

    public async put(url: string, body: any) {
        let header: any = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const accessToken = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
        if (accessToken) {
            header.headers['X-Cognito-Auth'] = accessToken;
        }

        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, header);

            return response;
        });
    }

    public async multiPartPost(url: string, body: FormData) {
        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, {
                method: 'POST',
                body,
            });

            return response;
        });
    }

    public async patch(url: string, body: any, customHeaders?: any) {
        return await this.wrapperTryCatch(async () => {
            const response = await fetch(this.getApiBase() + url, {
                method: 'PATCH',
                body: JSON.stringify(body),
                headers: customHeaders ? customHeaders : { 'Content-Type': 'application/json' },
            });

            return response;
        });
    }

    public async toastNotification(
        response: Response,
        successMessage: string,
        errorMessage: string
    ) {
        if (response?.status === 204 || response.status === 200) {
            toast.success(successMessage);
        } else {
            toast.error(errorMessage);
        }
    }

    public toast() {
        return toast;
    }

    private getApiBase = (): string => {
        if (IS_DEV) {
            return CONSTANTS.API_URL.DEV;
        }

        const isStage =
            window.location.hostname.includes('stage.') ||
            window.location.hostname.includes('staging.');
        if (isStage) {
            return CONSTANTS.API_URL.STAGE;
        }

        return CONSTANTS.API_URL.PROD;
    };
}

export default Service;

export enum ApiBase {
    spree,
    temp,
}
