import styles from './index.module.scss';
import {
    useGetConfig,
    useGetPaymentIntent,
    useGetInvoiceById,
} from '../../Features/payments/queries';
import { Navigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { appearance } from '../../Features/payments/constants';
import { PayInvoiceForm } from '../../Features/payments/PayInvoiceForm';
import CONSTANTS from '../../Constants';

export const Payments = () => {
    const {
        state: { paymentIntentId, invoiceId },
    } = useLocation();

    const configQuery = useGetConfig();
    const paymentIntentQuery = useGetPaymentIntent({ paymentIntentId });
    const invoiceQuery = useGetInvoiceById({ invoiceId });

    // Handle missing paymentIntentId or invoiceId. May want to change this redirection
    if (!paymentIntentId || !invoiceId) {
        return <Navigate to={CONSTANTS.SCREEN.PARTNER_PORTAL} />;
    }

    if (configQuery.isPending || paymentIntentQuery.isPending || invoiceQuery.isPending) {
        return <div>Loading...</div>;
    }

    if (configQuery.isError || paymentIntentQuery.isError || invoiceQuery.isError) {
        return <div>Error...</div>;
    }

    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div className={styles.details}>
                    {paymentIntentQuery.data && paymentIntentQuery.data.clientSecret ? (
                        <>
                            <div>
                                {configQuery.data.publishableKey &&
                                    paymentIntentQuery.data.clientSecret && (
                                        <div>
                                            <div className={styles.header}>
                                                <h2>
                                                    <strong>
                                                        ${invoiceQuery.data.amount_due / 100}.00
                                                    </strong>
                                                </h2>
                                                <p>Invoice #{invoiceQuery.data.id}</p>
                                            </div>

                                            <Elements
                                                stripe={loadStripe(configQuery.data.publishableKey)}
                                                options={{
                                                    clientSecret:
                                                        paymentIntentQuery.data.clientSecret,
                                                    appearance: appearance,
                                                }}
                                            >
                                                <PayInvoiceForm
                                                    clientSecret={
                                                        paymentIntentQuery.data.clientSecret
                                                    }
                                                />
                                            </Elements>
                                        </div>
                                    )}
                            </div>
                        </>
                    ) : (
                        <div className={styles.placehoder}>
                            <h3>Couldn't find a matching invoice</h3>
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};
