import { useQuery, useMutation } from '@tanstack/react-query';

export const entitleKeys = {
    all: ['entitlements'] as const,
    byId: (id: string) => ['entitlements', id] as const,
};

/**
 * Get Stripe config
 */
interface UseGetEntitlements {
    userId: string;
}

export const useGetEntitlements = ({ userId }: UseGetEntitlements) =>
    useQuery({
        queryKey: entitleKeys.byId(userId),
        queryFn: async () => {
            const response = await fetch(`http://localhost:8000/entitlements/${userId}`);
            return await response.json();
        },
        enabled: !!userId,
        select: (data) => data.map((entitlement: any) => entitlement.lookup_key),
    });

/**
 * Send Meter Event
 */

export const useSendTryOn = () =>
    useMutation({
        mutationFn: async (userId: string) => {
            const response = await fetch(`http://localhost:8000/meters/send-event`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId }),
            });
            return await response.json();
        },
    });
