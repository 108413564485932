import { observer } from 'mobx-react';
import { useTour } from './useTour';
import { useStores } from '../../Hooks/use-stores';

export const Tour = observer(() => {
    const { userStore } = useStores();

    const handleOnComplete = () => {
        userStore.setUserInfo({ ...userStore.userInfo, onboarded: new Date().toISOString() });
    };

    useTour({ handleOnComplete });

    return null;
});
