import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoBrand } from '../../../../Layout/Components/logo-brand';
import { theme } from '../../../../Style/theme';
import vogueLogo from '../../../../Assets/Img/Home/vogue-logo.webp';
import vogueStatic from '../../../../Assets/Img/Home/vogue-static.png';
import vogueVideoMobile from '../../../../Assets/MP4/mobile-video.mp4';
import vogueVideo from '../../../../Assets/MP4/desktop-video.mp4';

import './index.scss';
import { useInView } from 'framer-motion';

const VogueSection = () => {
    const videoRef = useRef(null);
    const { t } = useTranslation();
    const isInView = useInView(videoRef, { once: true, amount: 0.9 });

    return (
        <section ref={videoRef} className="VogueSection" id="VogueSection">
            <video
                ref={videoRef}
                autoPlay
                muted
                loop
                playsInline
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
                className="vogue-video"
            >
                <source
                    src="https://superb-actor-37d8b69f79.media.strapiapp.com/Desktop_Video_5d4ddce1cd.mp4"
                    type="video/mp4"
                />
            </video>
            <video
                ref={videoRef}
                autoPlay
                muted
                loop
                playsInline
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
                className="vogue-video-mobile"
            >
                <source
                    src="https://superb-actor-37d8b69f79.media.strapiapp.com/Mobile_Video_7603cb76bd.mp4"
                    type="video/mp4"
                />
            </video>
        </section>
    );
};

export default VogueSection;
