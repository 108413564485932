import { FormEvent, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import styles from './styles/checkout-form.module.scss';
import { Button2 } from '../../Components/Button2';
import { useStores } from '../../Hooks/use-stores';
import { entitleKeys } from '../partner-portal/queries';

const CUSTOMER_ID = 'cus_ROT5KxMPKCyJGs';

export const CheckoutForm = () => {
    const [paymentType, setPaymentType] = useState<'pay' | 'invoice'>('pay');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const { userStore } = useStores();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleValueChange = (value: string) => {
        setPaymentType(value as 'pay' | 'invoice');
    };

    const handleError = (error: StripeError | undefined) => {
        setLoading(false);
        setErrorMessage(error?.message ?? 'An unknown error occurred');
    };

    const payNow = async () => {
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        // Create the subscription
        const res = await fetch('http://localhost:8000/subscriptions/internal-testing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userStore.userInfo.id,
            }),
        });
        const { type, clientSecret } = await res.json();

        const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

        const { error } = await confirmIntent({
            elements,
            clientSecret,
            confirmParams: {
                return_url: 'http://localhost:3000/payments-success',
            },
        });

        setLoading(false);

        if (error) {
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError(error);
        } else {
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            queryClient.invalidateQueries({ queryKey: entitleKeys.byId(userStore.userInfo.id) });
        }
    };

    const sendInvoice = async () => {
        try {
            const res = await fetch('http://localhost:8000/subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: CUSTOMER_ID,
                }),
            });

            if (res.ok) {
                navigate('/partner-portal');
            }
        } catch (error) {
            console.error('Error sending invoice', error);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (paymentType === 'pay') {
            await payNow();
        } else {
            // Send invoice
            await sendInvoice();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <RadioGroup.Root
                className={styles.root}
                onValueChange={handleValueChange}
                value={paymentType}
                aria-label="View density"
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RadioGroup.Item className={styles.item} value="pay" id="r1">
                        <RadioGroup.Indicator className={styles.indicator} />
                    </RadioGroup.Item>
                    <label className={styles.label} htmlFor="r1">
                        Pay Now
                    </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RadioGroup.Item className={styles.item} value="invoice" id="r2">
                        <RadioGroup.Indicator className={styles.indicator} />
                    </RadioGroup.Item>
                    <label className={styles.label} htmlFor="r2">
                        Pay Later
                    </label>
                </div>
            </RadioGroup.Root>
            {/* <div className={styles.input}>
              <label className={styles.label} htmlFor="billingEmail">
                  Billing Email
              </label>
              <input
                  className={styles.Input}
                  type="text"
                  id="billingEmail"
                  placeholder="billing@company.com"
              />
          </div> */}
            {paymentType === 'pay' ? <PaymentElement /> : null}
            <Button2>{loading ? 'Payment Pending' : 'Subscribe'}</Button2>
        </form>
    );
};
