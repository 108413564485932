import CONSTANTS from '../../../Constants';
import { IS_DEV } from '../../../Utils/object';

export interface I_NAV_OPTIONS_CONTENT {
    title: string;
    href: string;
    type?: string;
}

export interface I_NAV_OPTIONS {
    title: string;
    href: string;
    type?: string;
    content?: I_NAV_OPTIONS_CONTENT[];
}

const cultureLinks: I_NAV_OPTIONS[] = [
    {
        title: 'header_nav_about_us_meet_our_team',
        href: CONSTANTS.SCREEN.MEET_OUR_TEAM,
    },
    {
        title: 'header_nav_about_us_life_at_spreeai',
        href: CONSTANTS.SCREEN.LIFE_AT_SPREEAI,
    },
];

if (IS_DEV) {
    cultureLinks.push({
        title: 'header_nav_engineering',
        href: CONSTANTS.SCREEN.ENGINEERING,
    });
}

export const NAV_OPTIONS: I_NAV_OPTIONS[] = [
    {
        title: 'header_nav_what_is_spree_ai',
        href: '#',
        content: [
            {
                title: 'header_nav_about_us',
                href: CONSTANTS.SCREEN.ABOUT_US,
            },
            {
                title: 'header_nav_impacts_and_inclusions',
                href: CONSTANTS.SCREEN.IMPACTS_AND_INCLUSIONS,
            },
            {
                title: 'header_nav_about_us_blog',
                href: CONSTANTS.SCREEN.BLOG,
            },
            {
                title: 'header_nav_about_us_faq',
                href: CONSTANTS.SCREEN.FAQ,
            },
        ],
    },
    {
        title: 'header_nav_culture',
        href: '#',
        content: cultureLinks,
    },
    {
        title: 'header_nav_careers',
        href: CONSTANTS.SCREEN.CAREERS,
    },
    {
        title: 'header_nav_contact_us',
        href: CONSTANTS.SCREEN.CONTACT_US,
    },
];
