import { ArrowLeft } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import styles from './styles/back-button.module.scss';

interface BackButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    path?: string;
}

export const BackButton = ({ path, ...props }: BackButtonProps) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        if (path) {
            navigate(path);
            return;
        }

        navigate(-1);
    };

    return (
        <button className={styles.back} onClick={handleGoBack} {...props}>
            <ArrowLeft size={32} fill="#000" />
            Back
        </button>
    );
};
