import moment from 'moment';
import { SupportedLanguage } from '../Stores/LanguageStore';
import CONSTANTS from '../Constants';

export function getKeyByValueRecursive(object: any, value: string) {
    if (value.includes('/blog/')) {
        // remove all the characters after the last slash
        value = value.substring(0, value.lastIndexOf('/'));
    }

    for (const key in object) {
        if (typeof object[key] === 'object') {
            const nestedKey: any = getKeyByValueRecursive(object[key], value);
            if (nestedKey !== null) {
                return key + '.' + nestedKey;
            }
        } else if (object[key] === value) {
            return key;
        }
    }
    return null;
}

export const calculateReadingTime = (characterCount: number) => {
    const NUMBER_OF_WORDS_PER_MINUTE = 200;
    const NUMBER_OF_CHARACTERS_PER_WORD = 6;

    var wordCount = Math.round(characterCount / NUMBER_OF_CHARACTERS_PER_WORD);

    var readingTimeMinutes = Math.round(wordCount / NUMBER_OF_WORDS_PER_MINUTE);

    return readingTimeMinutes;
};

export const formatDate = (date: string, currentLanguage: SupportedLanguage) => {
    if (!date) {
        return '';
    }

    if (currentLanguage === SupportedLanguage.EN) {
        return moment(date).format(CONSTANTS.MOMENT_FORMAT.MMMM_DD_COMMA_YYYY);
    }

    return moment(date).format(CONSTANTS.MOMENT_FORMAT.DD_MM_YYYY);
};

export function loadScript(src: string, attrs?: object, parentNode?: HTMLElement) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;

        for (const [k, v] of Object.entries(attrs || {})) {
            script.setAttribute(k, v);
        }
        script.setAttribute('is-add', 'false');

        script.onload = () => {
            script.onerror = script.onload = null;
            resolve(script);
        };

        script.onerror = () => {
            script.onerror = script.onload = null;
            reject(new Error(`Failed to load ${src}`));
        };

        const node = parentNode || document.head || document.getElementsByTagName('head')[0];
        node.appendChild(script);
    });
}

export const IS_DEV =
    window.location.hostname.includes('dev.') || window.location.hostname.includes('localhost');
