import { Laptop } from '@phosphor-icons/react';
import { Typography } from '../../Components/Typography';

import styles from './styles/support-desktop.module.scss';
import Copydeck from '../../i18n/Copydeck';

export const SupportDesktop = () => {
    return (
        <div className={styles.container}>
            <div className={styles.contentWrapper}>
                <Laptop size={80} fill="#2EB67D" weight="duotone" />
                <Typography variant="heading1" component="h1">
                    {Copydeck.desktopSupportDsTitle}
                </Typography>
                <Typography>{Copydeck.desktopSupportDsBody1}</Typography>
                <Typography>{Copydeck.desktopSupportDsBody2}</Typography>
            </div>
        </div>
    );
};
