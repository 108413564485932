import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { useGetConfig } from '../../Features/payments/queries';
import { appearance } from '../../Features/payments/constants';
import { CheckoutForm } from '../../Features/payments/CheckoutForm';
import styles from './index.module.scss';
import { Typography } from '../../Components/Typography';

export const Checkout = () => {
    const { data, isPending, isFetching } = useGetConfig();

    if (isPending || isFetching) {
        return <div>Loading...</div>;
    }

    const options: StripeElementsOptions = {
        mode: 'subscription',
        amount: 2000000,
        currency: 'usd',
        payment_method_types: ['card', 'us_bank_account'],
        // Fully customizable with appearance API.
        appearance: appearance,
    };

    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div className={styles.payment}>
                    <div className={styles.lineItems}>
                        {options.amount ? (
                            <>
                                <Typography
                                    variant="heading1"
                                    component="h1"
                                    style={{ marginBottom: '16px' }}
                                >
                                    Internal Testing
                                </Typography>
                                <Typography>$15,000.00 Monthly rate</Typography>
                                <Typography>$5,000.00 Initiation fee</Typography>
                                <div className={styles.divider} />
                                <Typography>${options.amount / 100}.00</Typography>
                            </>
                        ) : null}
                    </div>
                    <Elements stripe={loadStripe(data.publishableKey)} options={options}>
                        <CheckoutForm />
                    </Elements>
                </div>
            </div>
        </main>
    );
};
