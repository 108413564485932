import { useEffect } from 'react';

import { CoatHanger, Files, Person, TreeStructure, TShirt } from '@phosphor-icons/react';
import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';
import { BackButton } from '../../Features/partner-portal/BackButton';
import {
    DialogRoot,
    DialogTrigger,
    DialogContent,
    DialogTitle,
    DialogClose,
} from '../../Components/Dialog';
import styles from './index.module.scss';

export const ManageAssets = () => {
    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <BackButton style={{ marginBottom: '32px' }} />
                <Typography variant="heading1" component="h1" style={{ marginBottom: '24px' }}>
                    Manage Assets
                </Typography>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <Typography variant="subheading1" component="h4">
                            What We Need From You:
                        </Typography>
                        <ul className={styles.requirementsList}>
                            <li>
                                <TreeStructure fill="#2EB67D" size={32} />
                                <Typography>Metadata</Typography>
                            </li>
                            <li>
                                <TShirt fill="#2EB67D" size={32} />
                                <Typography>Front Flat Garment Images</Typography>
                            </li>
                            <li>
                                <Person fill="#2EB67D" size={32} />
                                <Typography>Model Garment Images</Typography>
                            </li>
                            <li>
                                <Files fill="#2EB67D" size={32} />
                                <Typography>Tech Packs</Typography>
                            </li>
                        </ul>
                        <Button2 color="secondary" variant="outlined">
                            View More Details
                        </Button2>
                    </div>
                    <div className={styles.card}>
                        <CoatHanger fill="#2EB67D" weight="duotone" size={72} />
                        <Typography variant="subheading1" component="h4">
                            What We Need From You:
                        </Typography>
                        <Typography>
                            Provide us more garment assets, and we’ll get you onboarded with
                            including these into your Internal Testing!
                        </Typography>
                        <UplodaAssetsDialog>
                            <Button2 color="secondary">Provide More Assets</Button2>
                        </UplodaAssetsDialog>
                    </div>
                </div>
                <div className={styles.myGarments}>
                    <div className={styles.garmentsHeader}>
                        <Typography>
                            <strong>My Garments</strong>
                        </Typography>
                    </div>
                    <div className={styles.garmentsList}>
                        <div className={styles.garmentListHeader}>
                            <div>Name</div>
                            <div>Status</div>
                            <div>Date Modified</div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.emptyState}>
                            <Typography variant="heading1" component="h2">
                                Provide Your Assets
                            </Typography>
                            <Typography>
                                Schedule a meeting to provide us any garment images, model garment
                                images, and tech packs you’d like to see in your internal testing
                                app.
                            </Typography>
                            <Typography>
                                You’ll be able to access any processing and status updates for your
                                garments here.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

interface UplodaAssetsDialogProps {
    children: React.ReactNode;
}

const UplodaAssetsDialog = ({ children }: UplodaAssetsDialogProps) => {
    return (
        <DialogRoot>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent aria-describedby="desc1 desc2">
                <div className={styles.dialogContent}>
                    <div className={styles.left}>
                        <DialogTitle asChild>
                            <Typography variant="heading1" component="h2">
                                Want To Test More?
                            </Typography>
                        </DialogTitle>

                        <Typography id="desc1">
                            Do you have a new season collection coming up? Want to test the
                            performance on additional garments?
                        </Typography>
                        <Typography id="desc2">
                            Provide us more garment assets, and we’ll get you onboarded with
                            including these into your Internal Testing!
                        </Typography>
                        <DialogClose asChild>
                            <Button2 color="secondary" variant="outlined">
                                Close
                            </Button2>
                        </DialogClose>
                    </div>
                    <div className={styles.right} style={{ backgroundColor: '#F3F3F3' }}>
                        <Calendar />
                    </div>
                </div>
            </DialogContent>
        </DialogRoot>
    );
};

const Calendar = () => {
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head?.appendChild(script);

        return () => {
            head?.removeChild(script);
        };
    }, []);

    return (
        <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/erikguntner-me"
            style={{ minWidth: '500px', height: '500px' }}
        />
    );
};
