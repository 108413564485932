export const SpreeLogoSmall = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8595 10.8718L13.5069 9.2945L13.5069 9.29449C13.4716 9.04915 13.4364 8.8038 13.4716 8.55845C13.5422 7.08635 14.6352 5.8596 16.0808 5.54415L17.3854 5.26375C17.4912 5.2287 17.6322 5.26375 17.738 5.33385C17.8437 5.40395 17.9143 5.5091 17.9495 5.6493L18.0906 6.3503C18.1611 6.59565 17.9848 6.841 17.738 6.9111L16.4334 7.1915C15.7987 7.29665 15.3051 7.78735 15.1641 8.41825C15.0935 8.6636 15.0935 8.8739 15.1641 9.11925L15.4814 10.4862C15.5519 10.7316 15.3756 10.9769 15.1288 11.047L14.4236 11.2223C14.1768 11.2924 13.93 11.1171 13.8595 10.8718ZM17.6456 10.588C18.384 10.588 18.9826 9.98945 18.9826 9.25105C18.9826 8.51265 18.384 7.91406 17.6456 7.91406C16.9072 7.91406 16.3086 8.51265 16.3086 9.25105C16.3086 9.98945 16.9072 10.588 17.6456 10.588Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6023 6.2803L9.98035 6.1401C9.73354 6.10505 9.48673 6.03495 9.27517 5.9298C7.90007 5.40405 7.05386 4.00205 7.23015 2.52995L7.37119 1.2331C7.37119 1.0929 7.44171 0.98775 7.54749 0.91765C7.65326 0.84755 7.75904 0.8125 7.90007 0.8125L8.60525 0.8826C8.85207 0.91765 9.06362 1.163 9.02836 1.40835L8.88732 2.7052C8.81681 3.3361 9.13414 3.967 9.69828 4.28245C9.90983 4.3876 10.1214 4.4577 10.3682 4.49275L11.7786 4.5979C11.79 4.60171 11.8019 4.60551 11.8141 4.6094C11.9141 4.64138 12.033 4.67941 12.0959 4.77315C12.1664 4.8783 12.2017 4.98345 12.2017 5.12365L12.1311 5.8597C12.1273 5.87111 12.1235 5.88293 12.1196 5.89503C12.0874 5.99445 12.0491 6.11266 11.9548 6.17515C11.8491 6.24525 11.7433 6.2803 11.6023 6.2803ZM11.3878 3.54897C12.1262 3.54897 12.7248 2.95038 12.7248 2.21199C12.7248 1.47359 12.1262 0.875 11.3878 0.875C10.6494 0.875 10.0508 1.47359 10.0508 2.21199C10.0508 2.95038 10.6494 3.54897 11.3878 3.54897Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.57136 7.05679C3.30976 7.05679 3.90835 6.4582 3.90835 5.7198C3.90835 4.9814 3.30976 4.38281 2.57136 4.38281C1.83296 4.38281 1.23438 4.9814 1.23438 5.7198C1.23438 6.4582 1.83296 7.05679 2.57136 7.05679ZM5.85432 8.52177C5.74855 8.73207 5.60751 8.94237 5.46647 9.11762C4.54974 10.2743 2.92783 10.6248 1.55274 10.099L0.353932 9.57327C0.248156 9.50317 0.142379 9.39802 0.10712 9.29287C0.0366019 9.18772 0.0718608 9.04752 0.10712 8.94237L0.389191 8.27642C0.459709 8.10117 0.636003 7.99602 0.812298 7.99602H0.812302C0.882819 7.99602 0.953335 7.99602 1.02385 8.03107L2.22265 8.55682C2.82206 8.80217 3.52723 8.69702 3.9856 8.27642C4.12664 8.13622 4.26767 7.92592 4.37345 7.71562L4.90233 6.41876C5.00811 6.17341 5.29018 6.06826 5.53699 6.17341L6.20691 6.45381C6.31269 6.48886 6.41847 6.59401 6.45372 6.69916C6.48898 6.80431 6.48898 6.94451 6.45372 7.04966L5.85432 8.48672V8.52177Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.64263 12.0661L6.87669 13.1176C7.05298 13.2928 7.19402 13.5031 7.33506 13.7134C8.11075 14.9402 7.96972 16.5875 6.98247 17.6741L6.10099 18.6555C6.03048 18.7256 5.9247 18.7957 5.78366 18.7957C5.64263 18.8307 5.53685 18.7606 5.43107 18.6905L4.90219 18.1998C4.69064 18.0246 4.69064 17.7442 4.86693 17.5339L5.7484 16.5525C6.17151 16.0968 6.27729 15.4309 6.03048 14.835C5.9247 14.6247 5.78366 14.4495 5.60737 14.2742L4.5496 13.3629C4.37331 13.1877 4.33805 12.9073 4.51434 12.697L4.97271 12.1362C5.04323 12.031 5.149 11.9609 5.29004 11.9609H5.3253C5.43107 11.9609 5.57211 11.996 5.64263 12.0661ZM3.0616 16.8771C3.79999 16.8771 4.39858 16.2785 4.39858 15.5401C4.39858 14.8017 3.79999 14.2031 3.0616 14.2031C2.3232 14.2031 1.72461 14.8017 1.72461 15.5401C1.72461 16.2785 2.3232 16.8771 3.0616 16.8771Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.942 13.484C13.3523 13.0985 14.8685 13.7644 15.6089 15.0262L16.2436 16.2179C16.3846 16.4282 16.2788 16.7086 16.0673 16.8488L15.4326 17.1993C15.3268 17.2344 15.1858 17.2694 15.08 17.2344C14.9743 17.1993 14.8685 17.1292 14.798 17.0241L14.128 15.8674C13.846 15.3767 13.2818 15.0613 12.7177 15.0613H12.5414C12.2946 15.0613 12.083 15.1314 11.8715 15.2716L10.6727 16.0076C10.5669 16.0777 10.4611 16.1128 10.3201 16.0777C10.2143 16.0427 10.1085 15.9726 10.038 15.8674L9.65016 15.2365C9.54438 14.9912 9.6149 14.7108 9.82645 14.5706L11.2368 13.7294H11.2721C11.4836 13.6242 11.6952 13.5541 11.942 13.484ZM12.585 19.1896C13.3234 19.1896 13.922 18.591 13.922 17.8526C13.922 17.1142 13.3234 16.5156 12.585 16.5156C11.8466 16.5156 11.248 17.1142 11.248 17.8526C11.248 18.591 11.8466 19.1896 12.585 19.1896Z"
                fill="white"
            />
        </svg>
    );
};
