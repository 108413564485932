import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import React from 'react';

import styles from './index.module.scss';

type ButtonProps = React.HTMLProps<HTMLButtonElement>;

interface Button2Props extends React.ComponentPropsWithoutRef<'button'> {
    asChild?: boolean;
    children: React.ReactNode;
    variant?: 'contained' | 'outlined' | 'text';
    color?: 'primary' | 'secondary';
    rounded?: boolean;
}

export const Button2 = React.forwardRef<HTMLButtonElement, Button2Props>(
    (
        { asChild, children, variant = 'contained', color = 'primary', rounded = true, ...props },
        forwardedRef
    ) => {
        const classes = classNames([
            {
                [styles.primaryContained]: color === 'primary' && variant === 'contained',
                [styles.primaryOutlined]: color === 'primary' && variant === 'outlined',
                [styles.secondaryContained]: color === 'secondary' && variant === 'contained',
                [styles.secondaryOutlined]: color === 'secondary' && variant === 'outlined',
                [styles.rounded]: rounded,
            },
            props.className,
        ]);

        const Comp = asChild ? Slot : 'button';

        return (
            <Comp {...props} ref={forwardedRef} className={classes}>
                {children}
            </Comp>
        );
    }
);
