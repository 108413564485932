import { LockSimple, RocketLaunch } from '@phosphor-icons/react';
import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import CONSTANTS from '../../Constants';
import { useStores } from '../../Hooks/use-stores';
import styles from './index.module.scss';
import { Button2 } from '../../Components/Button2';
import classNames from 'classnames';
import { BackButton } from '../../Features/partner-portal/BackButton';
import { useCustomPath } from '../../Hooks/use-custom-path';

export const InternalTesting = () => {
    const customPath = useCustomPath({ where: CONSTANTS.SCREEN.PARTNER_PORTAL });
    const { userStore } = useStores();
    const { phase_1, phase_2 } = userStore.userInfo;

    const handleContactUs = () => {
        userStore.setUserInfo({ phase_1: new Date().toISOString() });
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <BackButton path={customPath} />
                <Typography variant="heading1" component="h1">
                    Select An Internal Testing
                </Typography>
                <div className={styles.cards}>
                    <PhaseCard
                        active={phase_1}
                        title="Phase 1"
                        description="We’ll exchange resources to ensure that we can empower your team with the best experience. "
                        tag="Product Ingestion"
                    />
                    <PhaseCard
                        active={phase_2}
                        title="Phase 2"
                        description="Following this, we can begin with an early live internal rollout."
                        tag="Internal Soft Launch"
                    />
                </div>
                {!phase_1 && (
                    <Button2 variant="outlined" color="secondary" onClick={handleContactUs}>
                        Contact Us For More Info
                    </Button2>
                )}
                {phase_1 && !phase_2 && (
                    <Button2 variant="contained" color="secondary" asChild>
                        <Link pathConfig={{ where: CONSTANTS.SCREEN.PHASE_ONE }}>Continue</Link>
                    </Button2>
                )}
            </div>
        </div>
    );
};

interface PhaseCardProps {
    active: null | string;
    description: string;
    tag: string;
    title: string;
}

const PhaseCard = ({ active, description, tag, title }: PhaseCardProps) => {
    const cardStyles = classNames([
        styles.phaseCard,
        { [styles.active]: active, [styles.inactive]: !active },
    ]);

    return (
        <article className={cardStyles}>
            <div className={styles.cardContent}>
                <div className={styles.header}>
                    {active ? <RocketLaunch fill="#2EB67D" size={32} /> : <LockSimple size={32} />}
                    <div className={styles.tag}>
                        <Typography>{tag}</Typography>
                    </div>
                </div>
                <div>
                    <Typography component="h4">{title}</Typography>
                    <Typography>{description}</Typography>
                </div>
            </div>
            {!active && <div className={styles.overlay} />}
        </article>
    );
};
