import { useState, FormEvent } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import styles from './styles/pay-invoice.module.scss';
import { Button2 } from '../../Components/Button2';

interface PayInvoiceFormProps {
    clientSecret: string;
}

export const PayInvoiceForm = ({ clientSecret }: PayInvoiceFormProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleError = (error: StripeError | undefined) => {
        setLoading(false);
        setErrorMessage(error?.message ?? 'An unknown error occurred');
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/payments-success',
            },
        });

        setLoading(false);

        if (error) {
            console.log('error', error);
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError(error);
        } else {
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            queryClient.invalidateQueries({ queryKey: 'invoices' });
            navigate('/billing');
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <PaymentElement />
            <Button2 type="submit">Pay Invoice</Button2>
        </form>
    );
};
