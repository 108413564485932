import React, { useEffect, useState } from 'react';
import './index.scss';
import Form from '../../Components/Form';
import Img from '../../Assets/Img/Login/Welcome.webp';
import Logo from '../../Assets/Img/White_Spree_Logo.svg';
import { useTranslation } from 'react-i18next';
import { HandshakeIcon } from '../../Icons/handshake-icon';
import { DesktopIcon } from '../../Icons/desktop-icon';
import { theme } from '../../Style/theme';
import WhiteCard from '../../Components/WhiteCard';
import { LeftArrowIcon } from '../../Icons/left-arrow-icon';
import CONSTANTS from '../../Constants';
import UseServices from '../../Hooks/use-services';
import { useStores } from '../../Hooks/use-stores';
import { observer } from 'mobx-react-lite';
import useCustomNavigation from '../../Hooks/use-custom-navigation';
import { AlbumIcon } from '../../Icons/album-icon';
import Copydeck from '../../i18n/Copydeck';
import { IS_DEV } from '../../Utils/object';

const redirectToWelcome = false;

const Login = observer(() => {
    const { t } = useTranslation();
    const { userStore, tokenStore, loadingStore } = useStores();
    const { accountService } = UseServices();
    const nav = useCustomNavigation();

    const [userOrPasswordIncorrect, setUserOrPasswordIncorrect] = useState(false);
    const [userNeedConfirmMail, setUserNeedConfirmMail] = useState(false);
    const [userNeedApproval, setUserNeedApproval] = useState(false);

    const [forgotPassword, setForgotPassword] = useState(false);

    const onSubmit = async (values: any) => {
        if (!values) return false;

        try {
            loadingStore.addLoading();

            const response = await accountService.loginMailPassword({
                email: values.email,
                password: values.password,
            });

            if (!response) {
                throw new Error('ERR0');
            }

            tokenStore.setAccessTokenInLocalStorage(response.access_token);
            tokenStore.setRefreshTokenInLocalStorage(response.refresh_token);

            await userStore.login();
            if (redirectToWelcome) {
                nav(CONSTANTS.SCREEN.WELCOME);
            } else {
                nav(IS_DEV ? CONSTANTS.SCREEN.WELCOME : CONSTANTS.SCREEN.DASHBOARD);
            }

            setUserOrPasswordIncorrect(false);
            return true;
        } catch (error: any) {
            setUserNeedConfirmMail(false);
            setUserOrPasswordIncorrect(false);
            setUserNeedApproval(false);

            if (error.message === CONSTANTS.ERROR_CODE.ACCOUNT_NOT_CONFIRMED) {
                setUserNeedConfirmMail(true);
            } else if (error.message === CONSTANTS.ERROR_CODE.ACCOUNT_NOT_APPROVED) {
                setUserNeedApproval(true);
            } else {
                setUserOrPasswordIncorrect(true);
            }

            return false;
        } finally {
            loadingStore.removeLoading();
        }
    };

    const onSubmitForgotPassword = async (values: any) => {
        if (!values) return false;

        try {
            loadingStore.addLoading();

            const response = await accountService.forgotPassword({
                email: values.email,
            });

            if (!response) {
                throw new Error('ERR0');
            }

            setUserOrPasswordIncorrect(false);
            return true;
        } catch (error: any) {
            setUserOrPasswordIncorrect(true);

            return false;
        } finally {
            loadingStore.removeLoading();
        }
    };

    useEffect(() => {
        if (userStore.isLogin()) {
            nav(IS_DEV ? CONSTANTS.SCREEN.PARTNER_PORTAL : CONSTANTS.SCREEN.DASHBOARD);
        }
    }, [nav, userStore]);

    return (
        <div className="Login">
            {forgotPassword ? (
                <WhiteCard padding={20}>
                    <button
                        className="back text-body-bold cursor-pointer btn-delete-default-style"
                        onClick={() => setForgotPassword(false)}
                    >
                        <LeftArrowIcon fill={theme['element-greys-primary']} /> {t('back')}
                    </button>
                    <div className="forgot-content">
                        <h1 className="text-body-bold">{t('Login.forgot_password')}</h1>
                        <p className="text-body text-surface-grey-primary">
                            {t('ForgotPassword.enter_email_associated')}
                        </p>
                        <Form
                            onSubmit={onSubmitForgotPassword}
                            formHeaderLine={false}
                            className="form-container"
                            validation={[
                                {
                                    required: true,
                                    inputName: 'email',
                                    isEmail: true,
                                },
                            ]}
                        >
                            {userOrPasswordIncorrect ? (
                                <div className="account-password-incorrect">
                                    {t('ForgotPassword.forgot_password_error')}
                                </div>
                            ) : null}

                            <Form.BasicInput label={t('email')} name="email" type="email" />
                            <Form.Submit text={t('send')} />
                        </Form>
                        <button
                            className="have-account text-body cursor-pointer btn-delete-default-style"
                            onClick={() => setForgotPassword(false)}
                        >
                            {t('ForgotPassword.already_have_partner_access')}
                        </button>
                    </div>
                </WhiteCard>
            ) : (
                <>
                    <div className="box login-box">
                        <h1 className="title text-body-primary-font-bold">
                            {t('Login.login_spreeai')}
                        </h1>
                        <Form
                            onSubmit={onSubmit}
                            formHeaderLine={false}
                            className="form-container"
                            validation={[
                                {
                                    required: true,
                                    inputName: 'email',
                                },
                                {
                                    required: true,
                                    inputName: 'password',
                                },
                            ]}
                        >
                            {userOrPasswordIncorrect ? (
                                <div className="account-password-incorrect">
                                    {t('Login.account_password_incorrect')}
                                </div>
                            ) : null}

                            {userNeedConfirmMail ? (
                                <div className="account-password-incorrect">
                                    {t('Login.account_not_confirmed')}
                                </div>
                            ) : null}

                            {userNeedApproval ? (
                                <div className="account-password-incorrect">
                                    {t('Login.account_not_approved')}
                                </div>
                            ) : null}

                            <Form.BasicInput label={t('email')} name="email" type="email" />
                            <Form.PasswordInput label={t('password')} name="password" />

                            <Form.Submit text={t('Login.login_space')} />
                        </Form>
                        <button
                            className="forgot-pass text-body-primary-font cursor-pointer btn-delete-default-style"
                            onClick={() => setForgotPassword(true)}
                        >
                            {t('Login.forgot_password')}
                        </button>

                        <button
                            className="no-account text-body-primary-font cursor-pointer btn-delete-default-style"
                            onClick={() => nav(CONSTANTS.SCREEN.CREATE_ACCOUNT)}
                        >
                            {t('Login.no_account')}
                        </button>
                    </div>

                    <div className="box welcome-box" style={{ backgroundImage: `url(${Img})` }}>
                        <img loading="lazy" className="logo" src={Logo} alt="logo" />
                        <h1 className="title deprecated-text-display-medium">
                            {t('Login.welcome_back')}
                        </h1>
                        <div className="info-container">
                            <div className="info">
                                <HandshakeIcon fill={theme.white} width={24} />
                                <div>
                                    <h3 className="text-body-bold">
                                        {t('partner_resources_link')}
                                    </h3>
                                    <p className="text-body-small">{t('Login.partner_exp')}</p>
                                </div>
                            </div>
                            <div className="info">
                                <AlbumIcon fill={theme.white} width={24} />
                                <div>
                                    <h3 className="text-body-bold">
                                        {Copydeck.dashboardPageManageAssets}
                                    </h3>
                                    <p className="text-body-small">
                                        {Copydeck.createAccountManageAssetsBenefitParagraph}
                                    </p>
                                </div>
                            </div>
                            <div className="info">
                                <DesktopIcon fill={theme.white} width={24} />
                                <div>
                                    <h3 className="text-body-bold">{t('view_demo')}</h3>
                                    <p className="text-body-small">{t('Login.demo_exp')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export default Login;
