import { Check } from '@phosphor-icons/react';
import classNames from 'classnames';

import CONSTANTS from '../../Constants';
import styles from './index.module.scss';
import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import { Button2 } from '../../Components/Button2';
import { BackButton } from '../../Features/partner-portal/BackButton';
import { useStores } from '../../Hooks/use-stores';
import { useCustomPath } from '../../Hooks/use-custom-path';

export const Phase1 = () => {
    const { userStore } = useStores();
    const customPath = useCustomPath({ where: CONSTANTS.SCREEN.INTERNAL_TESTING });
    const { agreed_to_terms } = userStore.userInfo;

    const steps = [
        {
            title: 'Terms & Agreement',
            completed: Boolean(agreed_to_terms),
            inProgress: Boolean(!agreed_to_terms),
        },
        { title: 'Pending Items', completed: Boolean(null), inProgress: Boolean(agreed_to_terms) },
        { title: 'Access App', completed: Boolean(null), inProgress: Boolean(null) },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <BackButton path={customPath} />
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div>
                            <h4>Phase 1</h4>
                            <Typography variant="heading1" component="h1">
                                Internal Testing
                            </Typography>
                            <Typography>
                                This is your chance to bring your brand to life with our technology!
                                By using your own garments and input data, you’ll experience a
                                personalized, hands-on preview of how our solutions can seamlessly
                                integrate with your vision.
                            </Typography>
                        </div>
                        <ul className={styles.steps}>
                            {steps.map(({ title, completed, inProgress }, index) => (
                                <Step
                                    key={index}
                                    index={index}
                                    title={title}
                                    completed={completed}
                                    inProgress={inProgress}
                                />
                            ))}
                        </ul>
                        {steps[0].inProgress && (
                            <Button2 variant="contained" color="secondary" asChild>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.PRICING }}>
                                    Continue
                                </Link>
                            </Button2>
                        )}
                        {steps[1].inProgress && (
                            <Button2 variant="contained" color="secondary" asChild>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.PENDING_ITEMS }}>
                                    Continue
                                </Link>
                            </Button2>
                        )}
                    </div>
                    <div>
                        <div className={styles.placeholder} />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface StepProps {
    completed: boolean;
    index: number;
    inProgress: boolean;
    title: string;
}

const Step = ({ completed, index, inProgress, title }: StepProps) => {
    const stepStyles = classNames([
        styles.step,
        {
            [styles.completed]: completed,
            [styles.inProgress]: inProgress,
        },
    ]);

    return (
        <li key={index} className={stepStyles}>
            <span>
                {completed ? (
                    <Check fill="#ffffff" weight="bold" size={18} strokeWidth={2} />
                ) : (
                    index + 1
                )}
            </span>
            <Typography>{title}</Typography>
        </li>
    );
};
