import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { useGetConfig } from '../../Features/payments/queries';
import { appearance } from '../../Features/payments/constants';
import { Typography } from '../../Components/Typography';
import { EarlyAccessCheckoutForm } from '../../Features/payments/EarlyAccessCheckoutForm';
import styles from './index.module.scss';

export const CheckoutEarlyAccess = () => {
    const { data, isPending, isFetching } = useGetConfig();

    if (isPending || isFetching) {
        return <div>Loading...</div>;
    }

    const options: StripeElementsOptions = {
        mode: 'setup',
        payment_method_types: ['card', 'us_bank_account'],
        // Fully customizable with appearance API.
        appearance: appearance,
    };

    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div className={styles.payment}>
                    <div className={styles.lineItems}>
                        <Typography
                            variant="heading1"
                            component="h1"
                            style={{ marginBottom: '16px' }}
                        >
                            Early Access
                        </Typography>
                        <Typography>$0.04 per try on</Typography>
                    </div>
                    <Typography style={{ fontWeight: 700 }}>Add Payment Method</Typography>
                    <Elements stripe={loadStripe(data.publishableKey)} options={options}>
                        <EarlyAccessCheckoutForm />
                    </Elements>
                </div>
            </div>
        </main>
    );
};
