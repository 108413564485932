import { useLocation, useNavigate } from 'react-router-dom';
import { useGetInvoices } from '../../Features/payments/queries';
import moment, { unix } from 'moment';

import styles from './index.module.scss';
import { useStores } from '../../Hooks/use-stores';
import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';
import classNames from 'classnames';
import { Link } from '../../Components/Link';
import CONSTANTS from '../../Constants';
import { ArrowLeft } from '@phosphor-icons/react';
import { BillingPortalButton } from '../../Features/payments/BillingPortalButton';
import { IS_DEV } from '../../Utils/object';

export const Billing = () => {
    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <div>
                    <Link
                        pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}
                        className={styles.back}
                    >
                        <ArrowLeft size={32} fill="#000" />
                        Back
                    </Link>
                </div>
                <div className={styles.header}>
                    <h2 style={{ fontSize: '42px' }}>Billing & Payments</h2>
                    {IS_DEV && <BillingPortalButton>Manage Preferences</BillingPortalButton>}
                </div>
                <div className={styles.billingCards}>
                    <div className={classNames(styles.card)}>
                        <div>
                            <p>
                                <strong>Current Balance</strong>
                            </p>
                            <p>Due: November 31, 2024</p>
                        </div>

                        <div className={styles.price}>N/A</div>

                        <Button2>View Invoice</Button2>
                    </div>
                    <div className={classNames(styles.card, styles.upcoming)}>
                        <div>
                            <p>
                                <strong>Current Balance</strong>
                            </p>
                            <p>Due: November 31, 2024</p>
                        </div>

                        <div className={styles.price}>N/A</div>

                        <Button2 variant="outlined" color="secondary">
                            View Invoice
                        </Button2>
                    </div>
                </div>

                <div className={styles.table}>
                    <div className={classNames([styles.invoice, styles.billingHeader])}>
                        <div>Date</div>
                        <div>Description</div>
                        <div>Amount</div>
                        <div>Status</div>
                        <div></div>
                    </div>
                    <InvoiceList />
                </div>
            </div>
        </main>
    );
};

const InvoiceList = () => {
    const { userStore } = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    const handlePayInvoice = (invoiceId: string, paymentIntentId: string) => {
        navigate(`/invoice`, {
            state: {
                from: location,
                invoiceId,
                paymentIntentId,
            },
        });
    };

    const invoicesQuery = useGetInvoices({ userId: userStore.userInfo.id });

    if (invoicesQuery.isLoading) {
        return <p>Loading...</p>;
    }

    if (!invoicesQuery.data) {
        return (
            <div className={styles.noInvoices}>
                <Typography variant="heading1" component="h4">
                    No Record of Invoices
                </Typography>
                <Typography variant="body1" component="p">
                    No payments have been made yet, so there are currently no invoice records to
                    display.
                </Typography>
            </div>
        );
    }

    return (
        <ul>
            {invoicesQuery?.data?.length &&
                // @ts-ignore
                invoicesQuery.data.map((invoice) => {
                    const isPaid = invoice.status === 'paid';
                    const date = moment(unix(invoice.effective_at)).format('MM/DD/YYYY');

                    return (
                        <li className={styles.invoice} key={invoice.id}>
                            <div>
                                <Typography>
                                    <span>{date}</span>
                                </Typography>
                            </div>
                            <div>
                                <Typography>{invoice.lines.data[0]?.description ?? ''}</Typography>
                            </div>
                            <div>
                                <p>${invoice.amount_due / 100}</p>
                            </div>
                            <div>
                                <div
                                    className={classNames([
                                        styles.tab,
                                        isPaid ? styles.paid : styles.unpaid,
                                    ])}
                                >
                                    {invoice.status}
                                </div>
                            </div>
                            <div>
                                {invoice.hosted_invoice_url && invoice.status !== 'void' ? (
                                    <Button2
                                        className={styles.button}
                                        variant="outlined"
                                        color="secondary"
                                        asChild
                                    >
                                        <a
                                            href={invoice.hosted_invoice_url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View Invoice
                                        </a>
                                    </Button2>
                                ) : null}
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
};
