import { observer } from 'mobx-react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';

import { LogoBrand } from '../Components/logo-brand';
import CONSTANTS from '../../Constants';
import { Link } from '../../Components/Link';
import styles from './styles/portal-header.module.scss';
import { useStores } from '../../Hooks/use-stores';
import { SpreeLogoSmall } from '../../Icons/SpreeLogoSmall';
import useCustomNavigation from '../../Hooks/use-custom-navigation';

export const PortalHeader = () => {
    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <Link
                    pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}
                    className={styles.logo}
                >
                    <LogoBrand />
                </Link>
                <div className={styles.right}>
                    <nav className={styles.nav}>
                        <ul>
                            <li>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.INTERNAL_TESTING }}>
                                    Internal Testing
                                </Link>
                            </li>
                            <li>
                                <Link pathConfig={{ where: CONSTANTS.SCREEN.BILLING }}>
                                    Billing & Payments
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <Dropdown />
                </div>
            </div>
        </header>
    );
};

const Dropdown = observer(() => {
    const { tokenStore, userStore } = useStores();
    const nav = useCustomNavigation();

    const onClickLogout = () => {
        tokenStore.clearTokens();
        userStore.clearUserInfo();
        nav(CONSTANTS.SCREEN.HOME);
    };

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button className={styles.dropdownTrigger}>
                    <div>
                        <span>
                            <SpreeLogoSmall />
                        </span>
                        <span>{userStore.userInfo.first_name}</span>
                    </div>
                    <CaretDown className={styles.caret} fill="#ffffff" size={20} />
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className={styles.dropdownContent}>
                <DropdownMenu.Item className={classNames([styles.dropdownItem])} asChild>
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.INTERNAL_TESTING }}>
                        Internal Testing
                    </Link>
                </DropdownMenu.Item>
                <DropdownMenu.Item className={classNames([styles.dropdownItem])} asChild>
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.BILLING }}>Billing & Payments</Link>
                </DropdownMenu.Item>
                <DropdownMenu.Item
                    className={classNames([styles.dropdownItem, styles.logout])}
                    asChild
                >
                    <button onClick={onClickLogout}>Log Out</button>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
});
