import { useQuery, useMutation } from '@tanstack/react-query';

/**
 * Get Stripe config
 */
export const useGetConfig = () =>
    useQuery({
        queryKey: ['config'],
        queryFn: async () => {
            const response = await fetch('http://localhost:8000/config');
            return await response.json();
        },
    });

export const useGetCustomers = () => {
    const { isPending, error, data, isFetching, isError } = useQuery({
        queryKey: ['customers'],
        queryFn: async () => {
            const response = await fetch('http://localhost:8000/customer');
            return await response.json();
        },
    });

    return { data, isPending, error, isFetching, isError };
};

/**
 * Get Invoices
 */

interface UseGetInvoicesProps {
    userId: string;
}

export const useGetInvoices = ({ userId }: UseGetInvoicesProps) =>
    useQuery({
        queryKey: ['invoices', userId],
        queryFn: async () => {
            const response = await fetch(`http://localhost:8000/invoices/${userId}`);
            return await response.json();
        },
        enabled: !!userId,
    });

interface UseGetInvoiceByIdProps {
    invoiceId: string;
}

export const useGetInvoiceById = ({ invoiceId }: UseGetInvoiceByIdProps) =>
    useQuery({
        queryKey: ['invoice', invoiceId],
        queryFn: async () => {
            const response = await fetch(`http://localhost:8000/invoice/${invoiceId}`);
            return await response.json();
        },
        enabled: !!invoiceId,
    });

interface UseGetUpcomingInvoiceProps {
    userId: string;
}

export const useGetUpcomingInvoice = ({ userId }: UseGetUpcomingInvoiceProps) =>
    useQuery({
        queryKey: ['upcomingInvoice', userId],
        queryFn: async () => {
            const response = await fetch(`http://localhost:8000/invoices/${userId}/upcoming`);

            if (!response.ok) {
                throw new Error('Error getting billing portal URL');
            }

            return await response.json();
        },
        enabled: !!userId,
    });

/**
 * Create Internatl Testing Subscription
 */

interface UseCreateInternalTestingSubscription {
    customerId: string;
}

export const useCreateInternalTestingSubscription = ({
    customerId,
}: UseCreateInternalTestingSubscription) => {
    const { data, isPending, error } = useMutation({
        mutationFn: async (userId) => {
            const response = await fetch(`http://localhost:8000/subscriptions/internal-testing`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });
            return await response.json();
        },
    });

    return { paymentIntent: data, isPending, error };
};

/**
 * Get Billing Portal URL
 */

interface UseGetBillingPortalUrl {
    userId: string;
}

export const useGetBillingPortalUrl = ({ userId }: UseGetBillingPortalUrl) =>
    useQuery({
        queryKey: ['billingPortalUrl', userId],
        queryFn: async () => {
            const response = await fetch(
                `http://localhost:8000/billing-portal/${userId}?return_url=${window.location.href}`
            );

            if (!response.ok) {
                throw new Error('Error getting billing portal URL');
            }

            return await response.json();
        },
        enabled: false,
    });

/**
 * Get Payment Intent
 */

interface UseGetPaymentIntent {
    paymentIntentId: string;
}

export const useGetPaymentIntent = ({ paymentIntentId }: UseGetPaymentIntent) =>
    useQuery({
        queryKey: ['paymentIntent', paymentIntentId],
        queryFn: async () => {
            const response = await fetch(`http://localhost:8000/payment-intent/${paymentIntentId}`);
            return await response.json();
        },
        enabled: !!paymentIntentId,
    });
