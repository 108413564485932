import { Button2 } from '../../Components/Button2';
import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import CONSTANTS from '../../Constants';

import styles from './index.module.scss';

export const PaymentSuccess = () => {
    return (
        <main className={styles.main}>
            <div>
                <Typography variant="heading1" component="h1">
                    Your Payment Was A Success!
                </Typography>
                <Typography variant="body1" component="p">
                    Thank you for your continued support of SpreeAI.
                </Typography>
                <Button2 asChild variant="contained" color="primary">
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}>
                        Back to Home
                    </Link>
                </Button2>
            </div>
        </main>
    );
};
