import React from 'react';
import { motion } from 'framer-motion';

interface FadeUpProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    delay?: number;
    duration?: number;
    amount?: number;
    className?: string;
}

export const FadeUp = ({
    children,
    delay = 0,
    duration = 0.8,
    amount = 0.8,
    className,
}: FadeUpProps) => {
    return (
        <motion.div
            variants={{ hidden: { opacity: 0, y: 35 }, visible: { opacity: 1, y: 0 } }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount }}
            transition={{ delay, type: 'spring', bounce: 0, duration, damping: 20 }}
            className={className}
        >
            {children}
        </motion.div>
    );
};
