import React, { useRef, useState, useMemo, useEffect, createContext } from 'react';
import Slider, { Settings } from 'react-slick';
import { motion, useTime, useTransform } from 'framer-motion';

import styles from './index.module.scss';

interface CustomCarouselContextProps {
    activeSlide: number;
    direction: number;
    handlePrev: () => void;
    handleNext: () => void;
    handleSetActiveSlide: (index: number) => void;
    theme: 'dark' | 'light';
}

export const CustomCarouselContext = createContext<CustomCarouselContextProps>({
    activeSlide: 0,
    direction: 1,
    handlePrev: () => {},
    handleNext: () => {},
    handleSetActiveSlide: () => {},
    theme: 'dark',
});

interface CustomCarouselProps {
    children: React.ReactNode;
    className?: string;
    onNext?: () => void;
    onPrev?: () => void;
    settings?: Settings;
    showProgress?: boolean;
    theme?: 'dark' | 'light';
}

export const CustomCarousel = ({
    children,
    className,
    onNext,
    onPrev,
    settings,
    showProgress = false,
    theme = 'dark',
}: CustomCarouselProps) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [direction, setDirection] = useState(1);

    const sliderRef = useRef<Slider>(null);

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
            setDirection(1);

            if (onNext) {
                onNext();
            }
        }
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
            setDirection(-1);

            if (onPrev) {
                onPrev();
            }
        }
    };

    const handleBeforeChange = (oldIndex: number, newIndex: number): void => {
        setActiveSlide(newIndex);
    };

    const handleSetActiveSlide = (index: number) => {
        if (sliderRef.current) {
            if (index < activeSlide) {
                setDirection(-1);
            }

            if (index > activeSlide) {
                setDirection(1);
            }
            setActiveSlide(index);
            sliderRef.current.slickGoTo(index);
        }
    };

    useEffect(() => {
        if (settings?.autoplay) {
            const interval = setInterval(() => {
                handleNext();
            }, settings.autoplaySpeed || 5000);
            return () => clearInterval(interval);
        }
    });

    const sliderSettings: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ...settings,
    };

    return (
        <CustomCarouselContext.Provider
            value={{ activeSlide, direction, handlePrev, handleNext, handleSetActiveSlide, theme }}
        >
            <Slider
                ref={sliderRef}
                {...sliderSettings}
                className={className}
                beforeChange={handleBeforeChange}
                customPaging={(i) => (
                    <CustomDot
                        index={i}
                        activeSlide={activeSlide}
                        handleSetActiveSlide={handleSetActiveSlide}
                        sliderSettings={sliderSettings}
                        theme={theme}
                        showProgress={showProgress}
                        duration={sliderSettings.speed || 500}
                    />
                )}
            >
                {children}
            </Slider>
        </CustomCarouselContext.Provider>
    );
};

interface CustomDotProps {
    activeSlide: number;
    duration: number;
    handleSetActiveSlide: (index: number) => void;
    index: number;
    showProgress: boolean;
    sliderSettings: Settings;
    theme: 'dark' | 'light';
}

const CustomDot = ({
    activeSlide,
    duration,
    handleSetActiveSlide,
    index,
    showProgress,
    sliderSettings,
    theme,
}: CustomDotProps) => {
    const isActive = activeSlide === index;
    const expandedWidth = showProgress ? 150 : 30;

    const backgroundColor = useMemo(() => {
        if (theme === 'dark') {
            if (isActive && !showProgress) {
                return '#ffffff';
            }
            return 'rgba(255, 2555, 255, 0.5)';
        } else {
            if (isActive && !showProgress) {
                return '#2b2b2b';
            }
            return 'rgba(0, 0, 0, 0.5)';
        }
    }, [isActive, showProgress, theme]);

    return (
        <motion.button
            onClick={() => handleSetActiveSlide(index)}
            className={styles.dot}
            initial={{ width: isActive ? expandedWidth : 8 }}
            animate={{
                width: isActive ? expandedWidth : 8,
                backgroundColor: backgroundColor,
            }}
            transition={{ duration: duration / 1000 }}
        >
            {isActive && showProgress ? (
                <ProgressBar
                    duration={sliderSettings?.autoplaySpeed}
                    theme={theme}
                    width={expandedWidth}
                />
            ) : null}
        </motion.button>
    );
};

interface ProgressBarProps {
    duration: number | undefined;
    theme: 'dark' | 'light';
    width: number;
}

const ProgressBar = ({ duration = 2000, theme, width }: ProgressBarProps) => {
    const time = useTime();
    const innerWidth = useTransform(time, [0, duration], [0, width]);

    return (
        <motion.div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: 999,
                backgroundColor: theme === 'dark' ? '#ffffff' : '#2b2b2b',
                height: '8px',
                width: innerWidth,
            }}
        />
    );
};
