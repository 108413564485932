import { Appearance } from '@stripe/stripe-js';

export const appearance: Appearance = {
    theme: 'stripe',
    variables: {
        fontFamily: 'Poppins, sans-serif',
        borderRadius: '14px',
        colorPrimary: '#2b2b2b',
        gridRowSpacing: '18px',
        gridColumnSpacing: '18px',
    },
    rules: {
        '.Label': {
            fontWeight: '500',
            marginBottom: '8px',
        },
        '.Input': {
            border: '1px solid #2b2b2b',
        },
    },
};
