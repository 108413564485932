import { useMemo } from 'react';
import Shepherd, { Tour, TourOptions } from 'shepherd.js';

interface ShepherdTourOptions {
    tourOptions: TourOptions;
    renderSteps: (tour: Tour) => void;
}

export const useShepherdTour = ({ tourOptions, renderSteps }: ShepherdTourOptions) => {
    const tourObject = useMemo(() => {
        const tourInstance = new Shepherd.Tour(tourOptions);

        renderSteps(tourInstance as Tour);

        return tourInstance;
    }, [tourOptions, renderSteps]);

    return tourObject;
};
