export const ThreadsIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
        >
            <g clipPath="url(#clip0_11477_16045)">
                <path
                    d="M9.1395 18.5H9.13425C6.4485 18.482 4.38375 17.5962 2.99625 15.8682C1.7625 14.33 1.125 12.1895 1.104 9.5075V9.49475C1.1265 6.8105 1.76325 4.67225 2.99775 3.13325C4.38375 1.40375 6.45 0.518 9.135 0.5H9.1455C11.205 0.515 12.9278 1.04375 14.265 2.0735C15.5228 3.041 16.4085 4.421 16.8968 6.17375L15.3668 6.6005C14.5388 3.6305 12.4433 2.1125 9.13875 2.08925C6.95625 2.10575 5.30625 2.79125 4.23375 4.127C3.23025 5.378 2.712 7.1855 2.69175 9.5C2.712 11.8145 3.23025 13.622 4.2345 14.873C5.307 16.2102 6.95775 16.8965 9.1395 16.9108C11.1068 16.8958 12.408 16.4375 13.4895 15.377C14.7248 14.1673 14.703 12.6823 14.307 11.7785C14.0745 11.246 13.6523 10.8035 13.0815 10.466C12.9375 11.48 12.615 12.3005 12.1185 12.92C11.454 13.7465 10.5135 14.198 9.321 14.2625C8.4195 14.3113 7.55025 14.099 6.87675 13.6617C6.0795 13.145 5.613 12.3568 5.56275 11.4388C5.514 10.5463 5.86875 9.725 6.56025 9.12725C7.22025 8.55725 8.1495 8.222 9.2475 8.159C10.0041 8.11263 10.7636 8.14834 11.5125 8.2655C11.418 7.709 11.2313 7.2665 10.95 6.94775C10.5653 6.50825 9.969 6.2855 9.18075 6.28025H9.159C8.526 6.28025 7.665 6.45425 7.11825 7.27025L5.8005 6.38525C6.5355 5.29475 7.7265 4.69325 9.159 4.69325H9.192C11.5875 4.70825 13.0148 6.1745 13.1573 8.73425C13.2388 8.76925 13.319 8.80475 13.398 8.84075C14.5155 9.36575 15.333 10.1615 15.7635 11.1432C16.3613 12.5083 16.4168 14.7358 14.6025 16.5118C13.215 17.8693 11.532 18.4827 9.14475 18.4993L9.1395 18.5ZM9.89175 9.7325C9.71075 9.7325 9.526 9.73775 9.3375 9.74825C7.9605 9.8255 7.1025 10.4578 7.1505 11.3555C7.20075 12.2975 8.2395 12.7347 9.2385 12.6807C10.1565 12.632 11.352 12.2735 11.553 9.89825C11.0067 9.78429 10.4498 9.72873 9.89175 9.7325Z"
                    fill="#2B2B2B"
                />
            </g>
            <defs>
                <clipPath id="clip0_11477_16045">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
